import type { ExperimentDocument } from '../experiment/types';
import type { Hub2UserDocument } from '../hub2user/types';
import type { ImageArray } from '../image/types';
import type { InviteDocument, InviteModel, InviteSchema } from '../invite/types';
import type { ProjectLogDocument } from '../project-log/types';
import type { UserDocument, UserSchema, TUserPermission } from '../user/types';
import type { VersionDocument } from '../version';
import type { $TSFixMe, PLAN_NAME } from '@readme/iso';
import type { Request } from 'express';
import type { FastifyRequest } from 'fastify';
import type { FrontMatterResult } from 'front-matter';
import type JSZip from 'jszip';
import type { Document, Model } from 'mongoose';
import type { Logger } from 'pino';
import type { PartialDeep } from 'type-fest';

export interface ProjectPermission {
  fromGroup?: boolean;
  groupName?: string;
  project: ProjectDocument['_id'];
  userType: TUserPermission;
}

/**
 * These flag enum keys map to properties in the `flags` object in the DB so they **cannot** be
 * changed without a migration.
 */
export enum Flag {
  ALLOW_API_EXPLORER_JSON_EDITOR = 'allowApiExplorerJsonEditor',
  ALLOW_REUSABLE_OTPS = 'allowReusableOTPs',
  ALLOW_XFRAME = 'allowXFrame',
  ALWAYS_SHOW_DOC_PUBLISH_STATUS = 'alwaysShowDocPublishStatus',
  /** If access to API v1 and API v2 has been revoked. */
  API_ACCESS_REVOKED = 'apiAccessRevoked',
  CORRECT_NEW_LINES = 'correctnewlines',
  /** Enable MDX component editor. */
  CUSTOM_COMPONENTS = 'customComponents',
  DASH_REACT = 'dashReact',
  DIRECT_GOOGLE_TO_STABLE_VERSION = 'directGoogleToStableVersion',
  DISABLE_ANONYMOUS_FORUM = 'disableAnonForum',
  ENTERPRISE = 'enterprise',
  GRAPHQL = 'graphql',
  MDX = 'mdx',
  NEW_EDITOR_DASH = 'newEditorDash',
  OAUTH = 'oauth',
  OWLBOT_AI = 'owlbotAi',
  PASSWORDLESS_LOGIN = 'passwordlessLogin',
  RDMD_COMPATIBILITY_MODE = 'rdmdCompatibilityMode',
  REVIEW_WORKFLOW = 'reviewWorkflow',
  SINGLE_PROJECT_ENTERPRISE = 'singleProjectEnterprise',
  STAGING = 'staging',
  STAR = 'star',
  /** Enable the new in-Hub editing and admin experience. */
  SUPERHUB = 'superHub',
  /** Enable under development features for our new in-Hub editing and admin experience. */
  SUPERHUB_DEVELOPMENT = 'superHubDevelopment',
  TRANSLATION = 'translation',
}

export enum LoginType {
  CUSTOM_JWT = 'custom-jwt',
  OAUTH = 'oauth',
  README = 'readme',
  SAML = 'saml',
}

export enum CustomDomainValidationStatusType {
  LEGACY = 'legacy',
  UNVALIDATED = 'unvalidated',
  VALIDATED = 'validated',
}

export const LoginTypeArray = Object.values(LoginType);

export enum SSOStrategyType {
  ADFS = 'adfs',
  OIDC = 'oidc',
  PING_FEDERATE = 'pingfederate',
  SAML = 'samlp',
}

export const SSOStrategyTypeArray = Object.values(SSOStrategyType);

export interface SAMLIdP {
  description?: string;
  discovery_url?: string;
  displayName?: string;
  hubPreferred?: boolean;
  id: string;
  name: string;
  options?: Record<string, string>;
  sourceIdpName?: string;
  strategy?: SSOStrategyType;
}

export enum GitSyncProviderType {
  GITHUB = 'github',
  GITLAB = 'gitlab',
}

export const GitSyncProviderTypeArray = Object.values(GitSyncProviderType);

export interface APISetting {
  auth: string;
  authextra: {
    default: string;
    key: string;
    type: string;
  }[];
  contenttype: string;
  explorer: boolean;
  headers: {
    key: string;
    value: string;
  }[];
  jwt: boolean;
  jwt_secret: string;
  name: string;
  object_definitions: {
    name: string;
    parameters: {
      description: string;
      key: string;
      readOnly: boolean;
      ref: string;
      required: boolean;
      type: string;
    }[];
  }[];
  proxyEnabled: boolean;
  url: string;
}

export enum NavigationPageType {
  API_REFERENCE = 'reference',
  CHANGELOG = 'changelog',
  CUSTOM_PAGE = 'custompage',
  DISCUSSIONS = 'discuss',
  GUIDES = 'docs',
  HOME = 'home',
  LINK_URL = 'url',
  RECIPES = 'recipes',
  SEARCH_BOX = 'search',
  USER_CONTROLS = 'user',
}

export interface NavigationEntry {
  page?: string;
  text?: string;
  type: NavigationPageType | `${NavigationPageType}`;
  url?: string;
}

export interface VariableDefault {
  _id?: Document['id'];
  apiSetting?: Document['id'];
  default?: string;
  id?: string;
  name?: string;
  scheme?: string;
  source?: '' | 'security' | 'server';
  type?: string;
}

export interface ProjectSchema {
  _id: Document['id'];

  /** The original project document prior to editing. */
  _original: ProjectDocument;

  _parent?: ProjectDocument | ProjectDocument['_id'];

  /**
   * @deprecated This is a legacy access token system. Do not use this data.
   */
  accessToken: {
    client: Document['id'];
    scope?: string[];
    token: string;
  }[];

  adminLimitOverride: number;

  /** @deprecated */
  api: APISetting;
  /** @deprecated */
  apiAlt: APISetting[];

  /** Internal API Key */
  apiKey: string;
  appearance: {
    allowApiExplorerJsonEditor: boolean;
    body: {
      style: string;
    };
    changelog: {
      layoutExpanded: boolean;
      showAuthor: boolean;
      showExactDate: boolean;
    };
    colorScheme: 'dark' | 'light' | 'system';
    colors: {
      body_highlight: string;
      custom_login_link_color: string;
      header_text: string;
      highlight: string;
      main: string;
      main_alt: string;
    };
    favicon?: ImageArray;
    global_landing_page: {
      html: string;
      redirect: string;
    };
    header: {
      img?: ImageArray;
      img_pos: 'bc' | 'bl' | 'br' | 'cc' | 'cl' | 'cr' | 'tc' | 'tl' | 'tr';
      img_size: 'auto' | 'contain' | 'cover' | 'tile-x' | 'tile-y' | 'tile';
      style: 'custom' | 'gradient' | 'line' | 'overlay' | 'solid';
    };
    hideTableOfContents: boolean;
    hide_logo: boolean;
    html_body: string;
    html_footer: string;
    html_footer_meta: string;
    html_head: string;
    html_hidelinks: boolean;
    html_promo: string;
    javascript: string;
    javascript_hub2: string;
    landing: boolean;
    link_logo_to_url: boolean;
    loginLogo?: ImageArray;
    logo?: ImageArray;
    logo_large?: boolean;
    logo_white?: ImageArray;
    logo_white_use?: boolean;
    main_body: {
      type: string;
    };
    nextStepsLabel: string;
    overlay: 'blueprint' | 'circuits' | 'grain' | 'map' | 'triangles';
    promos: {
      extras?: {
        buttonPrimary: string;
        buttonSecondary: string;
        type: string;
      };
      text: string;
      title: string;
    }[];
    rdmd: {
      callouts: {
        useIconFont: boolean;
      };
      theme: {
        background?: string;
        border?: string;
        markdownEdge?: string;
        markdownFont?: string;
        markdownFontSize?: string;
        markdownLineHeight?: string;
        markdownRadius?: string;
        markdownText?: string;
        markdownTitle?: string;
        markdownTitleFont?: string;
        mdCodeBackground?: string;
        mdCodeFont?: string;
        mdCodeRadius?: string;
        mdCodeTabs?: string;
        mdCodeText?: string;
        tableEdges?: string;
        tableHead?: string;
        tableHeadText?: string;
        tableRow?: string;
        tableStripe?: string;
        tableText?: string;
        text?: string;
        title?: string;
      };
    };
    /** @deprecated Reference layouts are only supported in the legacy Hub2 API Explorer. */
    referenceLayout: 'column' | 'row';
    /** Controls `api` snippets in reference guides. */
    referenceSimpleMode: boolean;
    showMetricsInReference: boolean;
    showVersion: boolean;
    splitReferenceDocs: boolean;
    sticky: boolean;
    stylesheet: string;
    stylesheet_hub2: string;
    stylesheet_hub3: string;
    subheaderStyle: 'dropdown' | 'links';
    theme: 'line' | 'solid';
    typography: {
      body: string;
      headline: string;
      tk_body: string;
      tk_headline: string;
      tk_key: string;
      typekit: boolean;
    };
  };

  assetBaseUrl?: string;

  bing_verify?: string;
  canonicalUrl: string;
  childrenLimit: number;
  childrenProjects: ProjectDocument['_id'][] | ProjectDocument[];
  coupon: string;
  createdAt: Date;
  readonly currentOnboardingStep: number;

  custom_domain: string;
  custom_domain_added?: boolean;
  custom_domain_target?: string;
  custom_domain_validation_code?: string;
  custom_domain_validation_status?: CustomDomainValidationStatusType;
  dashLogin?: 'readme' | 'saml';

  dashLogoutUrl?: string;

  dashSessionExpirationTime: number;

  deployments: {
    author: UserDocument | UserDocument['_id'];
    date: Date;
  }[];
  description: string;
  domainSafelist?: string[];
  endUserSafelist?: string[];

  error404: string;

  experiments: ExperimentDocument['_id'][] | ExperimentDocument[];

  first_page: string | 'docs' | 'landing' | 'reference';

  flags: {
    [Flag.ALLOW_API_EXPLORER_JSON_EDITOR]: boolean;
    [Flag.ALLOW_REUSABLE_OTPS]: boolean;
    [Flag.ALLOW_XFRAME]: boolean;
    [Flag.ALWAYS_SHOW_DOC_PUBLISH_STATUS]: boolean;
    [Flag.API_ACCESS_REVOKED]: boolean;
    [Flag.CORRECT_NEW_LINES]: boolean;
    [Flag.CUSTOM_COMPONENTS]: boolean;
    [Flag.DASH_REACT]: boolean;
    [Flag.DIRECT_GOOGLE_TO_STABLE_VERSION]: boolean;
    [Flag.DISABLE_ANONYMOUS_FORUM]: boolean;
    [Flag.ENTERPRISE]: boolean;
    [Flag.GRAPHQL]: boolean;
    [Flag.MDX]: boolean;
    [Flag.NEW_EDITOR_DASH]: boolean;
    [Flag.OAUTH]: boolean;
    [Flag.OWLBOT_AI]: boolean;
    [Flag.PASSWORDLESS_LOGIN]: 'default' | 'disable' | 'force';
    [Flag.RDMD_COMPATIBILITY_MODE]: boolean;
    [Flag.REVIEW_WORKFLOW]: boolean;
    [Flag.SINGLE_PROJECT_ENTERPRISE]: boolean;
    [Flag.STAGING]: boolean;
    [Flag.STAR]: boolean;
    [Flag.SUPERHUB]: boolean;
    [Flag.SUPERHUB_DEVELOPMENT]: boolean;
    [Flag.TRANSLATION]: boolean;
  };

  fullBaseUrl: string;

  git: {
    migration?: {
      createRepository?: { end?: Date; start: Date; status: 'failed' | 'processing' | 'successful' };
      enableSuperhub?: { end?: Date; start: Date; status: 'failed' | 'processing' | 'successful' };
      migratingPages?: { end?: Date; start: Date; status: 'failed' | 'processing' | 'successful' };
    };
    repositoryName: string;
    sync: {
      connectedRepository?: {
        connectedAt: Date;
        connectedBy: UserDocument;
        id: string;
        name: string;
        // External Unique ID from github or gitlab
        organization: Document['id'];
        provider: 'github' | 'gitlab';
        url: string;
        visibility: 'private' | 'public';
      };
      github: {
        _id?: Document['id'];
        installationId: number;
      }[];
      gitlab: {
        _id?: Document['id'];
        accessToken: string;
        refreshToken: string;
      }[];
      verifySyncHash: string;
    };
  };

  glossaryTerms: {
    _id?: Document['id'];
    definition: string;
    term: string;
  }[];

  google_analytics?: string;

  google_verify?: string;

  gracePeriod: {
    enabled: boolean;
    endsAt: Date | null;
  };

  graphqlSchema: string;

  hashedPassword?: string;

  healthCheck: {
    provider: '' | 'manual' | 'statusPage';
    settings: {
      page: string;
      status: boolean;
      url: string;
    };
  };
  heapanalytics?: string;

  hstsIncludeSubdomains: boolean;

  hubLogin: string;

  hubNewMemberPermissions: ProjectPermission[];

  hubSamlPermissionMapping: {
    groupName: string;
    project: ProjectDocument | ProjectDocument['_id'];
    userType: TUserPermission;
  }[];
  readonly inactive_password: string | false;

  integrations: {
    login: {
      externalId: string;
      kind: 'aws';
      region: string;
      roleArn: string;
      usagePlanId: string;
    };
  };

  intercom?: string;
  intercom_secure?: string;

  intercom_secure_emailonly: boolean;

  internal: '' | 'admin' | 'custom-login' | 'password';
  invoice: {
    address: string;
    contact: string;
    name: string;
  };
  is_active?: boolean;
  jwtExpirationTime: number;
  jwt_secret: string;
  koala: string;
  landing_bottom: $TSFixMe[];
  localize: string;
  logoutUrl?: string;

  mdxMigrationStatus: 'error' | 'mdx' | 'migrating' | 'rdmd' | 'reverting';

  metrics: {
    customTTL: number;
    meteredBilling?: {
      lastEventId: string;
      lastEventTimestamp: number;
    };
    monthlyLimit: number;
    monthlyPurchaseLimit: number;
    thumbsEnabled: boolean;
  };

  modules: {
    changelog: boolean;
    custompages: boolean;
    discuss: boolean;
    docs: boolean;
    examples: boolean;
    graphql: boolean;
    landing: boolean;
    reference: boolean;
    suggested_edits: boolean;
    tutorials: boolean;
  };

  name: string;

  nav_names: {
    changelog: string;
    discuss: string;
    docs: string;
    recipes: string;
    reference: string;
    tutorials: string;
  };

  newMemberPermissions: ProjectPermission[];
  notes: string;

  oauth: {
    authorizationPath: string;
    id: string;
    scope: string;
    script: string;
    secret: string;
    tokenPath: string;
  };

  /** This is a Mongoose virtual that calls `ProjectModel#oauthEnabled()` whenever accessed. */
  readonly oauthEnabled: boolean;
  oauth_code: {
    config: string;
    index: string;
    now_url: string;
  };
  oauth_url: string;

  onboardingCompleted: {
    api: boolean;
    appearance: boolean;
    documentation: boolean;
    domain: boolean;
    /** Metrics API Keys setup step */
    jwt: boolean;
    /** Metrics API Logs - whether logs have been received */
    logs: boolean;
    /** Metrics API Logs setup step */
    metricsSDK: boolean;
  };

  oss: {
    additional: string;
    company: boolean;
    open: boolean;
  };

  owlbot: {
    customization: {
      answerLength: 'long' | 'medium' | 'short';
      customTone: string;
      defaultAnswer: string;
      forbiddenWords: string;
      tone: 'custom' | 'neutral' | 'professional' | 'whimsical';
    };
    enabled: boolean;
    isPaying: boolean;
    lastIndexed: Date;
  };

  owner: UserDocument | UserDocument['_id'];

  plan: PLAN_NAME;
  planOverride: PLAN_NAME;
  planSchedule: {
    changeDate: Date | null;
    nextPlan: PLAN_NAME | null;
    stripeScheduleId: string | null;
  };
  planStatus: string;
  planStripe: string;

  /** This is a Mongoose virtual that calls `ProjectModel#getPlanTrial(). */
  readonly plan_trial: PLAN_NAME;

  promos: {
    extras: {
      buttonPrimary: string;
      buttonSecondary: string;
      type: string;
    };
    text: string;
    title: string;
  }[];

  reCaptchaSecretKey?: string;
  reCaptchaSiteKey?: string;
  readmeScore: {
    components: $TSFixMe;
    totalScore: number;
  };
  redirects: string;

  reference: {
    /**
     * Controls if we use should use `default` in the API Explorer even if the parameter or request
     * body property is not marked as `required`.
     */
    alwaysUseDefaults: boolean;
    defaultExpandResponseExample: boolean;
    defaultExpandResponseSchema: boolean;
    enableOAuthFlows: boolean;
  };

  /** @deprecated this is here to support the dash as we transition to the new request paradigm in api v2. */
  req: FastifyRequest | Request;
  robotsAllow: boolean;

  salesforce: {
    accountId?: string;
    migrationComplete: boolean;
    projectId?: string;
  };

  salt?: string;

  samlIdps: SAMLIdP[];

  samlPermissionMapping: {
    _id?: Document['_id'];
    groupName: string;
    project: ProjectDocument | ProjectDocument['_id'];
    userType: TUserPermission;
  }[];

  segmentio?: string;
  segmentio_domain?: string;
  seo: {
    overwrite_title_tag?: boolean;
  };
  sitemap: boolean;

  ssl: {
    cloudflareCertificateIdentifier: string;
    minTLS: string;
    validationContent: {
      http_body: string;
      http_url: string;
    };
    validationMethod: 'auto' | 'manual';
  };

  stable: VersionDocument | VersionDocument['_id'];

  steps: {
    step1: boolean;
    step2: boolean;
    step3: boolean;
  };

  subdomain: string;
  subpath: string;

  topnav: {
    bottom: NavigationEntry[];
    left: NavigationEntry[];
    right: NavigationEntry[];
  };

  translate: {
    crawl: boolean;
    key_private?: string;
    key_public: string;
    key_secret?: string;
    languages: {
      code: string;
      name: string;
    }[];
    org_name: string;

    project_name: string;
    provider: 'transifex';

    show_widget: boolean;
  };

  trial: {
    trialDeadlineEnabled: boolean;
    trialEndsAt: Date;
  };

  tutorialOnboarding: {
    endpoints: boolean;
    published: boolean;
  };

  updatedAt: Date;
  url: string;
  useCase: 'api' | 'product';

  variableDefaults: VariableDefault[];

  /**
   * @deprecated do not use this, it will be removed soon. Instead make a request to the versions collection, or add a populate virtual
   */
  versions: VersionDocument['_id'][] | VersionDocument[];

  webhooks: {
    action: 'login';
    url: string;
  }[];

  zendesk?: string;
}

interface DocumentMethods {
  acceptRequest(
    inviteId: string,
    userType?: TUserPermission,
    isEndUser?: boolean,
  ): Promise<(Hub2UserDocument | UserDocument)[]>;

  addChild(potentialChild: ProjectDocument): Promise<ProjectDocument>;

  addMember(
    email: string,
    admin: UserDocument,
    options?: {
      inviteSAML?: boolean;
      resolveToHub?: boolean;
      samlPreference?: string;
      userType?: TUserPermission;
    },
  ): Promise<{
    added: boolean;
    email: string;
    status?: InviteSchema['status'];
    userType: string;
  }>;

  addMembers(
    inviter: UserDocument,
    emails: UserSchema['email'][],
    perms: ProjectPermission[],
    options?: {
      inviteSAML?: boolean;
      redirect?: string;
      teammates?: boolean;
    },
  ): Promise<{
    added: UserDocument[];
    invited: InviteDocument[];
  }>;

  addPermission: (user: Hub2UserDocument | UserDocument, userType: TUserPermission) => Promise<void>;

  bootstrap({ owner, src, useCase }: { owner: UserDocument; src: string; useCase: string }): Promise<ProjectDocument>;

  canAddChild(potentialChild: ProjectDocument): boolean;

  canRemoveChild(childToRemove: ProjectDocument['_id']): boolean;

  clone: ({ subdomain }: { subdomain: string }) => Promise<ProjectDocument>;

  convertTableLists(): Promise<void>;

  createDuplicateSAML(sourceIdpName: string, hubPreferred: boolean): Promise<void>;

  createParent(subdomain: ProjectSchema['subdomain'], email: string, childrenLimit: number): Promise<ProjectDocument>;

  deleteAlgoliaIndex(): Promise<$TSFixMe>;

  deleteDuplicateSAML(sourceIdpName: string): Promise<void>;

  deleteGroup(): Promise<$TSFixMe>;

  deleteInvitation(email: string): Promise<void>;

  deleteInvitations(emails: string): Promise<void>;

  deleteMember(id: ProjectDocument['_id'], email: string): Promise<$TSFixMe[]>;

  encryptPassword(password: string): string;

  flagProjectForIndexing(version?: ProjectDocument['_id'], targetModels?: $TSFixMe): Promise<$TSFixMe>;

  generatePdf(version: VersionDocument, logger: Logger): Promise<Buffer>;

  generateZipFile(versions: VersionDocument[], logger: Logger): Promise<JSZip>;

  getDeploymentHistory(project: ProjectDocument, fromIndex: number, toIndex: number): Promise<$TSFixMe>;

  getEmailLogo(): string;

  getInvited(): ReturnType<InviteModel['find']>;

  getMembers(userType?: TUserPermission, getCount?: boolean, preserveModel?: boolean): Promise<$TSFixMe[]>;

  getMembersCount(): Promise<{
    admins: ReturnType<DocumentMethods['getMembers']>;
    readOnly: ReturnType<DocumentMethods['getMembers']>;
  }>;

  getStagingDiff(): Promise<{
    awaitingDeploy: (ProjectSchema & {
      logs: Record<string, $TSFixMe>;
    })[];
    projects: ProjectSchema[];
  }>;

  getStagingTimeline(fromDate: Date, toDate: Date): Promise<$TSFixMe[]>;

  getVariableDefaults(): ProjectSchema['variableDefaults'];

  hydrateAll(): Promise<ProjectDocument & { users: (ProjectPermission & UserDocument)[] }>;

  importVersions: (data: Record<string, FrontMatterResult<unknown>>, userId: string) => Promise<VersionDocument[]>;

  loadMembers(
    query?: {
      endusers?: boolean;
      input?: string;
      limit?: number;
      owner?: boolean;
      page?: number;
      projects?: string;
      userType?: TUserPermission;
    },
    isGod?: boolean,
  ): Promise<$TSFixMe>;

  log(
    type: ProjectLogDocument['type'],
    additionalData: $TSFixMe,
    cb?: (err: Error | null, log: ProjectLogDocument) => void,
  ): void;

  /* Mongoose virtual */
  password: string;

  plan_details_actual: (type?: string) => any;

  removeChild(childId: ProjectDocument['_id']): Promise<ProjectDocument>;

  reorderPages(version: VersionDocument, body: $TSFixMe): $TSFixMe;

  resendInvite(
    inviter: UserDocument,
    emails: string[],
    options?: { teammates?: boolean },
  ): Promise<{
    invited: $TSFixMe;
  }>;

  salesforceSync(): Promise<$TSFixMe>;

  scanCompatMode(): Promise<$TSFixMe>;

  search(
    versionRaw: VersionDocument,
    query: $TSFixMe,
    sort: boolean,
    cb: (err: Error | null, results: $TSFixMe[]) => void,
  ): void;

  stripeSync(stripeToken?: string): $TSFixMe;

  testWebhookSetup(url: string, email: string): Promise<$TSFixMe>;

  toggleActiveForChildren(active: boolean): ReturnType<ProjectModel['updateMany']>;

  toggleOwlbot(enable: boolean): Promise<void>;

  updateChildList(newChildren: Pick<ProjectDocument, '_id'>[]): Promise<ProjectDocument>;

  updateHubLoginSettings(): ProjectDocument;

  updateOnboardingStep(step: keyof ProjectSchema['onboardingCompleted']): void;

  verifyPassword(password: string): boolean;
}

interface StaticMethods {
  acceptRequest(
    inviteId: InviteDocument['_id'],
    userType?: TUserPermission,
    isEndUser?: boolean,
  ): Promise<Hub2UserDocument[] | UserDocument[]>;

  base_url(project: ProjectDocument, environment?: string, staging?: boolean): string;

  checkDns(project: ProjectDocument): Promise<{ message: string; success: boolean | null }>;

  connectToPublishedProd(): Promise<$TSFixMe>;

  customCss(...args: $TSFixMe): string;

  expireGracePeriods(): Promise<void>;

  favicon(project: ProjectDocument, opts?: { mimeType?: boolean; userAgent?: string }): string;

  filterForReactApp(
    project: ProjectDocument,
    user: Hub2UserDocument | UserDocument,
    isDash?: boolean,
    isEnterpriseChild?: boolean,
  ): $TSFixMe;

  filterSensitive(body: PartialDeep<ProjectSchema>): ProjectSchema;

  findWithParent(projectIds: ProjectSchema['_id'][]): Promise<ProjectDocument[]>;

  flattenProjects(project: ProjectDocument): ProjectDocument[];

  getAlgoliaModules(modules: Partial<ProjectSchema['modules']>[]): string;

  getAllowedProjects(
    projects: ProjectDocument[],
    dynamicUser: Hub2UserDocument | UserDocument,
    allowGroups?: boolean,
  ): { project: ProjectDocument['_id']; userType: TUserPermission }[];

  getAuth0CallbackURL({
    customer,
    protocol,
    project,
    isDash,
  }: {
    customer?: string;
    isDash: boolean;
    project: ProjectDocument;
    protocol: string;
  }): Promise<string>;

  getAuth0Settings(samlConnection: string): {
    clientId: string;
    clientSecret: string;
    tenantBaseUrl: string;
  };

  getEnterpriseProductionConnection(): Promise<$TSFixMe>;

  getHomeUrl(project: ProjectDocument, ...paths: string[]): string;

  getInstance(fromPojo: Partial<ProjectSchema>): ProjectDocument;

  getOrganizations(): Promise<ProjectDocument[]>;

  getParent(project: ProjectDocument): Promise<ProjectDocument | null>;

  getPlanDetails(project: ProjectSchema, permission?: string): $TSFixMe;

  getPlanTrial(project: ProjectSchema): ProjectSchema['plan'];

  getRedirects(project: ProjectSchema): string[];

  getSecretKey(project: ProjectDocument): string;

  getSortedVersions(project: ProjectDocument): VersionDocument[];

  getVariableDefaults(project: ProjectDocument): ProjectSchema['variableDefaults'];

  gracePeriodExpired(project: ProjectDocument): boolean;

  hasCompletedOnboardingStep(project: ProjectDocument, step: keyof ProjectSchema['onboardingCompleted']): boolean;

  hasOneChild(project: ProjectDocument): boolean;

  hasParent(project: ProjectDocument): boolean;

  hydrateParentForGLP(proj: ProjectDocument, lang?: string): Promise<ProjectDocument>;

  inheritParent(parent: ProjectDocument, project: ProjectDocument): ProjectDocument;

  isCustomDomainAllowed(project: ProjectDocument): boolean;

  isEnterprise(project: ProjectDocument): boolean;

  isHubEditable(project: ProjectDocument): boolean;

  isMember(project: ProjectDocument, user: Hub2UserDocument | UserDocument): boolean;

  isParent(project: ProjectSchema): boolean;

  isStagingEnabled(project: ProjectDocument): ProjectSchema['flags']['staging'];

  is_admin(project: ProjectDocument, user: Hub2UserDocument | UserDocument): boolean;

  list(
    options: { criteria: Record<string, unknown>; page?: number; perPage?: number },
    cb: (err: Error | null, projects: ProjectDocument[]) => void,
  ): void;

  load(
    subdomain: Partial<ProjectSchema> | ProjectSchema['subdomain'],
    opts?: { isGod?: boolean },
  ): Promise<ProjectDocument>;

  load(
    subdomain: Partial<ProjectSchema> | ProjectSchema['subdomain'],
    cb: (err: Error | null, project: ProjectDocument) => void,
  ): void;

  load(
    subdomain: Partial<ProjectSchema> | ProjectSchema['subdomain'],
    opts: { isGod?: boolean },
    cb: (err: Error | null, project: ProjectDocument) => void,
  ): void;

  loadInitialData(subdomain: string, potentialChild?: string): Promise<ProjectDocument & { child?: ProjectDocument }>;

  loadInitialDataForAPI(
    subdomainusePlanPermissions: string,
    potentialChild?: string,
  ): Promise<ProjectDocument & { child?: ProjectDocument }>;

  loginUrl(
    project: ProjectDocument,
    opts?: {
      redirect?: string;
      resolveToHub?: boolean;
      samlPreference?: string;
      staging?: boolean;
    },
  ): string;

  makeSalt(): string;

  markdownTheme(project: ProjectDocument): { className: string; style: string };

  pingWebhook(
    project: ProjectDocument,
    hub2user: Hub2UserDocument,
    additionalData?: Record<string, unknown>,
  ): Promise<$TSFixMe>;

  provisionDefaultPerms(project: ProjectSchema, user: UserDocument, teammate?: boolean): ProjectPermission[];

  renderGlobalLandingPage(
    project: ProjectDocument,
    currentLang?: string,
    user?: PartialDeep<Hub2UserDocument>,
  ): $TSFixMe;

  runHealthCheck(project: ProjectDocument): Promise<{ healthy: boolean; url?: string }>;

  sensitiveKeys(): string[];

  shouldGateDash(project: ProjectDocument): boolean;

  ssoLoginUrl(project: ProjectSchema, samlName?: string): string;

  synchronize(targetProject: ProjectDocument, deployAll?: boolean): Promise<$TSFixMe>;

  transferAllOwnerships(parent: ProjectDocument, toThatUser: UserDocument): Promise<void>;

  transferOwnership(
    project: ProjectDocument,
    fromThisUser: UserDocument,
    toThatUser: UserDocument,
    req?: Request,
    refundOptions?: { currency?: string; refund?: number },
  ): Promise<void>;

  updateUsers(
    userIds: UserDocument['_id'][],
    perms: ProjectPermission[],
    owner: UserDocument,
    endUser?: boolean,
    hasGroups?: boolean,
  ): Promise<UserDocument>;

  updateableFlags(): string[];

  validateImport(data: Record<string, FrontMatterResult<unknown>>): boolean;
}

export interface ProjectDocument extends Document, ProjectSchema, DocumentMethods {}

export interface ProjectModel extends Model<ProjectDocument>, StaticMethods {
  landingPageSelect: string;
  reducedProjectSelect: string;
}

export interface ProjectClientSide
  extends Omit<
    ProjectSchema,
    // The `Document.parent` property in Mongoose isn't compatible with how we use `Project.parent`
    // in client-side code so it needs to be omitted and retyped.
    'parent'
  > {
  /**
   * This is the parent project. This `parent` value is **only** present in the React app. If
   * you're serverside you should use `_parent` instead.
   */
  parent?: ProjectSchema;
}
